/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import localforage from 'localforage';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ReactComponent as NotificationOff } from '../../../assets/icons/disabled-bell.svg';
import { ReactComponent as NotificationOn } from '../../../assets/icons/enabled-bell.svg';
import { ReactComponent as RestartIcon } from '../../../assets/icons/rotate-ccw.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ConfigContext } from '../../../context/ConfigContext';
import { DropdownMenu, DropdownMenuListItem } from './DropdownMenu';

const HeaderUi = styled.div`
    padding: 1rem;
    background-color: ${({ theme: { header } }) => header.color};
    color: ${({ theme: { header } }) => header.textColor};
    display: flex;
    flex-shrink: 0;
    background: ${({ theme: { header } }) =>
            `linear-gradient(135deg,${header.background.color1} 0%,${header.background.color2} 100%)`};
    position: relative;
`;

const Title = styled.span`
    font-size: 1.5rem;
`;
const SubTitle = styled.span`
    font-size: 0.9rem;
`;

const CloseButton = styled.button`
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme: { header } }) => header.textColor};
    &:hover {
        opacity: 0.8;
    }
`;

const isFirefox = navigator.userAgent.indexOf('Firefox') > 0;

const defaultSettings = isFirefox ? { enabled: false } : { enabled: true };

const Header = () => {
  const config = useContext(ConfigContext);
  const hasNotifications = config.hasNotifications;
  const isRestartConversationEnabled = config.allowRestartConversation;
  const isMobile = config.isMobile;
  const [notificationSettings, setNotificationSettings] = useState(undefined);
  useEffect(() => {
    localforage.getItem('notification_settings', (err, value) => {
      if (!value && hasNotifications) {
        localforage
          .setItem('notification_settings', defaultSettings)
          .then(() => setNotificationSettings(defaultSettings));
      } else if (!!value) {
        setNotificationSettings(value);
      }
    });
  }, [setNotificationSettings]);
  const onChangeNotifSettings = useCallback(
    async settings => {
      try {
        await localforage.setItem('notification_settings', settings);
        window.parent.postMessage(JSON.stringify({ action: 'update-notifications', settings }), '*');
        setNotificationSettings(settings);
      } catch (error) {
        console.error(error);
      }
    },
    [setNotificationSettings],
  );

  const restartConversation = async () => {
    try {
      await localStorage.clear();
      await localforage.clear();
      window.parent.postMessage(JSON.stringify({ action: 'restart-conversation' }), '*');
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    window.parent.postMessage(JSON.stringify({ action: 'close' }), '*');
  };

  return (
    <HeaderUi>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
        <Title id='chatbotTitle'>
          {config.translations.header[config.translations.locale] || config.translations.header.DEFAULT}
        </Title>{' '}
        <SubTitle>
          Powered by{' '}
          <a
            style={{ color: 'white' }}
            href='https://www.proximus.be/en/id_cl_interact/companies-and-public-sector/it-services/customer-interactions/interact.html'
          >
            #Interact
          </a>
        </SubTitle>
      </div>
      {isMobile && (
        <CloseButton onClick={handleClose} aria-label="Close chat">
          <CloseIcon style={{ width: '24px', height: '24px' }} />
        </CloseButton>
      )}
      {
        (!!notificationSettings || isRestartConversationEnabled) && (
          <DropdownMenu>
            {notificationSettings && notificationSettings.enabled && (
              <DropdownMenuListItem
                onClick={() =>
                  onChangeNotifSettings({ ...(notificationSettings || {}), enabled: !notificationSettings.enabled })
                }>
                <NotificationOn style={{ height: '16px' }} />
                Turn off notifications
              </DropdownMenuListItem>
            )}
            {notificationSettings && !notificationSettings.enabled && (
              <DropdownMenuListItem
                onClick={() =>
                  onChangeNotifSettings({ ...(notificationSettings || {}), enabled: !notificationSettings.enabled })
                }>
                <NotificationOff
                  style={{ height: '16px' }} />
                Turn on notifications
              </DropdownMenuListItem>
            )}

            {
              isRestartConversationEnabled && (
                <DropdownMenuListItem onClick={restartConversation}>
                  <RestartIcon style={{ height: '16px', width: '16px' }} />
                  Restart conversation
                </DropdownMenuListItem>
              )
            }
          </DropdownMenu>
        )
      }
    </HeaderUi>
  );
};

export default Header;
